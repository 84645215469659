import React from 'react';
import Layout from '../components/layout';
import { graphql, Link } from 'gatsby';
import SEO from '../components/seo';
import './blog-post.sass';
import '../styling/prism.css';
//import Disqus from 'disqus-react';

class BlogPostTemplate extends React.Component {

  render() {
    const post = this.props.data.markdownRemark;
    // const disqusShortname = 'nodevisionblog';
    // const disqusConfig = {
    //   url: `${this.props.data.site.siteMetadata.siteUrl}${post.fields.slug}`,
    //   identifier: post.id,
    //   title: post.frontmatter.title,
    // };
    return (
      <Layout pageType="blog" pathname={this.props.location.pathname}>
        <SEO
          title={post.frontmatter.metaTitle}
          description={post.frontmatter.metaDesc}
        />
        <div className="page-blog-post">
          <section className="heading">
            <div className="container">
              <h1>{post.frontmatter.title}</h1>
              <div className="author">{post.frontmatter.author}</div>
              <div className="date">{post.frontmatter.date}</div>
              {post.frontmatter.tags && (
                <div className="tags">
                  {post.frontmatter.tags.split(';').map((el, idx) => (
                    <span key={idx}>{el}</span>
                  ))}
                </div>
              )}
            </div>
            <img src={post.frontmatter.image} alt={post.frontmatter.title} />
          </section>
          <section className="post">
            <div className="container">
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
              <div className="go-back-wrapper">
                <Link className="is-button with-border is-light" to="/blog">
                  Back to list
                </Link>
              </div>
              {/*<Disqus.DiscussionEmbed*/}
              {/*  shortname={disqusShortname}*/}
              {/*  config={disqusConfig}*/}
              {/*/>*/}
              {/*<div id="disqus_thread"></div>*/}
            </div>
          </section>
        </div>
        <script src="/js/prism.js" />
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        author
        image
        tags
        metaTitle
        metaDesc
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
